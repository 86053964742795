import {Alert, Button, Card} from "react-bootstrap";

const TagCard = ({
	tag,
 	itemOnDelete,
	beforeDeleteHandler,
	deleteHandler,
	cancelHandler,
	onClickHandler
}) => {
	return (
		<Card key={tag.name} style={{ width: '100%'}}>
			<Card.Img
				variant="top"
				height={200}
				src={`${window.__CONFIG__.REACT_APP_BASE_URL}${tag.imageUrl}`}
			/>
			{(itemOnDelete && itemOnDelete.id === tag.id) ? (
				<Alert
					variant="warning"
					style={{ width: '100%', margin: '0', display: 'flex', justifyContent: 'space-between'}}
				>
					<div className="flex-column-center">
						<p>{`Вы действительно хотите удалить тег?`}</p>
						<div className="flex">
							<Button
								variant="success"
								className="marginRight20"
								onClick={() => deleteHandler(tag)}
							>Удалить</Button>
							<Button
								variant="danger"
								onClick={() => cancelHandler()}
							>Отмена</Button>
						</div>
					</div>
				</Alert>
			) : (
				<Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
					<Card.Title><b>{tag.name}</b></Card.Title>
					<div className="flex-column-center">
						<Button
							className="marginBottom10"
							variant="dark"
							onClick={() => onClickHandler(tag)}
							style={{width: '100%', height: '38px'}}
						>
							Редактировать
						</Button>
						<Button
							variant="dark"
							onClick={() => beforeDeleteHandler(tag)}
							style={{width: '100%', height: '38px'}}
						>
							Удалить
						</Button>
					</div>
				</Card.Body>
			)}
		</Card>
	)
};

export default TagCard