import 'bootstrap/dist/css/bootstrap.css';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import './pagination.scss';

const PaginationComponent = ({activePage, handlePageChange, total, limit = 15}) => {

	return (
		<div className="flex-center marginTop20 PaginationRoot">
			<PaginationControl
				page={activePage}
				between={4}
				total={total}
				limit={limit}
				changePage={(page) => {
					handlePageChange(page)
				}}
				ellipsis={1}
			/>
		</div>
	)
};

export default PaginationComponent;