import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";

const EventModel = ApplicationRecord.extend({
	static: { jsonapiType: "events" },
	attrs: {
		id: attr(),
		params: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
	}
})

export default EventModel