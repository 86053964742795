import {useEffect, useState} from 'react';
import {Button, Form, InputGroup} from "react-bootstrap";
import MarketplaceModel from "../../../models/marketplaceModel";
import MarketplaceLocationsModel from "../../../models/marketplaceLocationsModel";
import {getAllCities} from '../../../api/cityAPI';
import MerchantPageWrapper from "../../../components/pageWrappers/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import AddLocation from "../../../components/addLocation/addLocation";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {attachHandler, uploadHandler} from '../../../helpers/directUpload';
import {getAllTags} from "../../../api/tagsAPI";
import MarketplaceTagModel from "../../../models/marketplaceTagModel";


const CreateMarketplacePage = () => {
	const [cities, setCities] = useState([]);
	const [locations, setLocations] = useState([]);
	const [logo, setLogo] = useState('');
	const [tags, setTags] = useState([]);
	const [selectedTag, setSelectedTag] = useState({value: 'all'});
	const [tagArray, setTagArray] = useState([]);
	const [background, setBackground] = useState('');
	const [mainImage, setMainImage] = useState('');
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		status: 'draft',
		city: '',

		organization_info: '',
		bank_info: '',
		email: '',
		phone: '',
	});
	const [errors, setErrors] = useState({
		name: null,
		description: null,
		city: null,
		email: null
	});

	const getTags = async () => {
		await getAllTags()
		.then(res => setTags(res.filter(t => !t.rootTag)));
	};

	useEffect(() => {
		getAllCities().then(
			(res) => setCities(res)
		)
		getTags();
	}, []);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}))
	};

	const attachLogoHandler = (file) => {
		attachHandler(file, (f, c) => setLogo({ file: f, checkSum: c }));
	};

	const attachBackgroundHandler = (file) => {
		attachHandler(file, (f, c) => setBackground({ file: f, checkSum: c }));
	};

	const attachMainHandler = (file) => {
		attachHandler(file, (f, c) => setMainImage({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		const EMAIL_REGEXP = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

		if (!formData.name || !formData.description || !formData.city || (formData.email && !EMAIL_REGEXP.test(formData.email))) {
			setErrors({
				name: !formData.name ? 'Название не заполнено' : null,
				description: !formData.description ? 'Описание не заполнено' : null,
				city:  !formData.city ? 'Город не выбран' : null,
				email: formData.email ? !EMAIL_REGEXP.test(formData.email) ? 'Некорректный email': null : null
			})
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		} else {
			const currentMarketplace = new MarketplaceModel();

			currentMarketplace.unverifiedChanges = {
				name: formData.name,
				description: formData.description
			};
			currentMarketplace.city = cities.find(c => c.id === formData.city);
			currentMarketplace.legalData = {
				organization_info: formData.organization_info,
				bank_info: formData.bank_info,
				email: formData.email,
				phone: formData.phone,
			}

			if (logo?.file) {
				currentMarketplace.unverifiedLogo = logo && (await uploadHandler(logo));
			}

			if (background?.file) {
				currentMarketplace.unverifiedCatalogBackground = background && (await uploadHandler(background));
			}

			if (mainImage?.file) {
				currentMarketplace.unverifiedImage = mainImage && (await uploadHandler(mainImage));
			}

			await currentMarketplace.save({ with: "city.id"})
			.then(() => {
				locations.forEach(async loc => {
					const currentLocation = new MarketplaceLocationsModel();
					currentLocation.name = loc.name;
					currentLocation.address = loc.address;
					currentLocation.availableForSelfPickup = loc.available_for_self_pickup;
					currentLocation.lat = loc.lat;
					currentLocation.lng = loc.lng;
					currentLocation.marketplace = currentMarketplace;
					await currentLocation.save({ with: "marketplace.id"})
				});

				tagArray.forEach(async t => {
					const newMarketTag = new MarketplaceTagModel();
					newMarketTag.tag = t.tag;
					newMarketTag.marketplace = currentMarketplace;

					await newMarketTag.save({ with: ['marketplace.id', 'tag.id']});
				})
			})
			.then(() => {
				setTimeout(() => {
					window.location.pathname = '/merchant'
				}, 1000)
			})
		}
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper lightVersion>
				<div>
					<h1 className="marginBottom20">Создание нового бизнеса</h1>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon1">Название (обязательное поле)</InputGroup.Text>
						<Form.Control
							placeholder="Название"
							aria-label="Название"
							aria-describedby="basic-addon1"
							value={formData.name}
							isInvalid={errors.name}
							onChange={e => changeHandler('name', e.target.value)}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.name}
						</Form.Control.Feedback>
					</InputGroup>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Описание (обязательное поле)</InputGroup.Text>
						<Form.Control
							placeholder="Описание"
							aria-label="Описание"
							aria-describedby="basic-addon2"
							value={formData.description}
							isInvalid={errors.description}
							onChange={e => changeHandler('description', e.target.value)}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.description}
						</Form.Control.Feedback>
					</InputGroup>
					<InputGroup className="mb-3 marginBottom20">
						<InputGroup.Text id="basic-addon2">Город (обязательное поле)</InputGroup.Text>
						<Form.Select
							value={formData.city}
							isInvalid={errors.city}
							onChange={(c) => changeHandler('city', c.target.value)}
						>
							<option>Выберите город</option>
							{cities.map(city => (
								<option key={city.name} value={city.id}>{city.name}</option>
							))}
						</Form.Select>
						<Form.Control.Feedback type="invalid">
							{errors.city}
						</Form.Control.Feedback>
					</InputGroup>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Реквизиты организации</InputGroup.Text>
						<Form.Control
							placeholder="ИНН, КПП и далее по форме"
							aria-label="Реквизиты организации"
							aria-describedby="basic-addon2"
							value={formData.organization_info}
							onChange={e => changeHandler('organization_info', e.target.value)}
						/>
					</InputGroup>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Банковские реквизиты организации</InputGroup.Text>
						<Form.Control
							placeholder="Введите банковские реквизиты организации"
							aria-label="Банковские реквизиты организации"
							aria-describedby="basic-addon2"
							value={formData.bank_info}
							onChange={e => changeHandler('bank_info', e.target.value)}
						/>
					</InputGroup>
					<div className="grid2x ">
						<InputGroup className="mb-3">
							<InputGroup.Text id="basic-addon2">Email для связи</InputGroup.Text>
							<Form.Control
								placeholder="Введите email для связи"
								aria-label="Email для связи"
								aria-describedby="basic-addon2"
								value={formData.email}
								isInvalid={errors.email}
								onChange={e => changeHandler('email', e.target.value)}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.email}
							</Form.Control.Feedback>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text id="basic-addon2">Телефон для связи</InputGroup.Text>
							<Form.Control
								placeholder="Введите телефон для связи"
								aria-label="Телефон для связи"
								aria-describedby="basic-addon2"
								value={formData.phone}
								onChange={e => {
									if (e.target.value.length <= 11) {
										changeHandler('phone', e.target.value.replace(/\D/, ''))
									}
								}}
							/>
						</InputGroup>
					</div>
					<div className="block marginBottom10">
						<h5 className="marginBottom20"><b>Категория бизнеса</b></h5>
						<div className='grid2x'>
							<div className="flex flex-wrap">
								{tagArray?.length === 0 ? (
									<p>У бизнеса нет категорий</p>
								) : (
									tagArray.map(t => (
										<div className="ActiveTag">
											<b>{t.tag.name}</b>
											<Button
												variant="outline-danger"
												onClick={() => setTagArray(tagArray.filter(tf => tf.tag.id !== t.tag.id))}
											>Удалить</Button>
										</div>
									))
								)}
							</div>
							<div className='flex'>
								<InputGroup style={{width: '400px'}}>
									<InputGroup.Text>Категория</InputGroup.Text>
									<Form.Select
										aria-label="Выберите роль"
										aria-placeholder="Выберите роль"
										onChange={e => setSelectedTag({
											value: e.target.value, tag: tags.find(i => i.id === e.target.value)
										})}
										value={selectedTag.value}
									>
										<option value={'all'} disabled>Выберите категорию</option>
										{tags.map(tag => (
											<option
												value={tag.id}
												key={tag.id}
												disabled={tagArray.find(t => t.tag.id === tag.id)}
											>
												{tag.name}
											</option>
										))}
									</Form.Select>
								</InputGroup>
								<Button
									variant='dark'
									disabled={selectedTag.value === 'all'}
									onClick={() => {
										setTagArray(p => ([...p, selectedTag]))
										setSelectedTag({value: 'all'});
									}}
								>Добавить категорию</Button>
							</div>
						</div>
					</div>
					<DropZoneUploader
						preview={logo}
						acceptPreviewHandler={(f) => {
							if (f) attachLogoHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите логотип вашего бизнеса в формате jpeg или png"
					/>
					<DropZoneUploader
						preview={background}
						acceptPreviewHandler={(f) => {
							if (f) attachBackgroundHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите фон для вашего каталога в формате jpeg или png"
					/>
					<DropZoneUploader
						preview={mainImage}
						acceptPreviewHandler={(f) => {
							if (f) attachMainHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите ваше основное изображение в формате jpeg или png"
					/>

					<AddLocation
						locations={locations}
						setNewLocation={(newLocation) => setLocations(p => ([...p, newLocation]))}
						removeLocationHandler={(id) => {
							setLocations(locations.filter(i => i.arrayId !== id))
						}}
					/>
				</div>

				<Button
					variant="dark"
					style={{display: 'block', width: '100%'}}
					onClick={() => submitHandler()}
					className="marginBottom20"
				>Сохранить</Button>
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default CreateMarketplacePage