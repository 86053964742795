import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";
import MarketplaceModel from "./marketplaceModel";

const MarketplaceLocationsModel = ApplicationRecord.extend({
	static: { jsonapiType: "marketplace_locations" },
	attrs: {
		id: attr(),
		name: attr(),
		address: attr(),
		availableForSelfPickup: attr(),
		lat: attr(),
		lng: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		marketplace: belongsTo(MarketplaceModel),
	}
})

export default MarketplaceLocationsModel