import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Spinner} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/pageWrappers/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {getProductsForMarketplace} from "../../../api/productAPI";
import ProductCard from "../../../components/cards/productCard/productCard";

const ProductsPage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [products, setProducts] = useState([]);

	useEffect(() => {
		getProductsForMarketplace(marketplaceId)
		.then(res => {
			setProducts(res);
		})
	}, []);

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{products
					? products.length > 0
						? (
							<>
								<div className="block marginBottom20">
									<h1 className="marginBottom20">Черновики товаров</h1>
									<div className="grid4x">
										<Button variant="dark" onClick={() => {navigate(`/merchant/${marketplaceId}/products/create`);}}>
											<h3>Добавить товар </h3>
										</Button>
										{products.filter(i => i.status === 'draft').map(product => (
											<ProductCard
												key={product.id}
												product={product}
												marketplaceId={marketplaceId}
											/>
										))}
									</div>
								</div>
								<div className="block marginBottom20">
									<h1 className="marginBottom20">Товары ожидающие верификации</h1>
									<div className="grid4x">
										{products.filter(i => i.status === 'verification').map(product => (
											<ProductCard
												key={product.id}
												product={product}
												marketplaceId={marketplaceId}
											/>
										))}
									</div>
								</div>
								<div className="block marginBottom20">
									<h1 className="marginBottom20">Опубликованные товары</h1>
									<div className="grid4x">
										{products.filter(i => i.status === 'published').map(product => (
											<ProductCard
												key={product.id}
												product={product}
												marketplaceId={marketplaceId}
											/>
										))}
									</div>
								</div>
							</>
						)
						: (
							<div className="flex-center">
								<h1 className="marginRight20">Товары не найдены</h1>
								<Button variant="dark" onClick={() => {navigate(`/merchant/${marketplaceId}/products/create`);}}>
									Добавить товар
								</Button>
							</div>
						)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default ProductsPage