import {Button, Card} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const UserCard = ({
	keyRole,
	user,
	deactivateHandler,
	deleteHandler
}) => {
	const navigate = useNavigate();

	return (
		<Card style={{ width: '100%', background: user.isDeactivated ? 'lightpink' : 'transparent'}}>
			<Card.Body>
				<Card.Text>
					<b>
						{!user.name && !user.surname && !user.patronym
							? ' Имя не найдено'
							: ` ${user?.name || ''} ${user?.surname || ''} ${user?.patronym || ''}`
						}
					</b>
				</Card.Text>

				{(keyRole === 'admin' || keyRole === 'merchant') && (
					<Card.Text><b>Email</b> - {user.email || 'не найден'}</Card.Text>
				)}
				{(keyRole === 'user' || keyRole === 'courier') && (
					<Card.Text><b>Телефон</b> - {user.phone || 'не найден'}</Card.Text>
				)}
			</Card.Body>
			<Card.Body style={{display: 'flex', columnGap: '6px', rowGap: '6px', flexWrap: 'wrap'}}>
				{!user.isDeactivated ? (
					<Button style={{width: '100%'}} variant="dark" onClick={() => {navigate(`/admin/users/${user.id}`)}}>
						Редактировать
					</Button>
				) : (
					<p>Пользователь деактивирован</p>
				)}
				{(keyRole === 'user') && (
					<Button  style={{width: '100%'}} variant="dark" onClick={() => deactivateHandler(user)}>
						{user.isDeactivated ? 'Активировать' : 'Деактивировать'}
					</Button>
				)}
				<Button  style={{width: '100%'}} variant="dark" onClick={() => deleteHandler(user)}>
					Удалить
				</Button>
			</Card.Body>
		</Card>
	)
};

export default UserCard;