import {Button, Card} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const ProductCard = ({ product, marketplaceId, withDescription = false }) => {
	const navigate = useNavigate();

	return (
		<Card>
			<div style={{width: '100%', height: '200px'}}>
				<img
					style={{ objectFit: 'cover', objectPosition: 'center', height: '100%', width: '100%'}}
					src={`${window?.__CONFIG__?.REACT_APP_BASE_URL}${product.unverifiedImageUrl || product.imageUrl}`} alt=""/>
			</div>
			<Card.Body>
				{product.discountPrice / 100 > 0 ? (
					<div className="flex">
						<h5><b>{`${(product.discountPrice / 100)} ₽`}</b></h5>
						<h6 style={{margin: '0 0 0 12px'}}>{`${product.price / 100} ₽`}</h6>
					</div>
				) : (
					<>
						<h5><b>{`${(product.price / 100)} ₽`}</b></h5>
					</>
				)}
				<Card.Title><b>{product?.unverifiedChanges?.name || product.name}</b></Card.Title>
				{withDescription && (
					<Card.Text>{product?.unverifiedChanges?.description || product.description}</Card.Text>
				)}
				<Button variant="dark" onClick={() => {navigate(`/merchant/${marketplaceId}/products/${product.id}`);}}>
					Редактировать
				</Button>
			</Card.Body>
		</Card>
	)
};

export default ProductCard;