import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";

const MarketplaceTagModel = ApplicationRecord.extend({
	static: { jsonapiType: "marketplace_tags" },
	attrs: {
		id: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		marketplace: belongsTo(),
		tag: belongsTo(),
	}
})

export default MarketplaceTagModel