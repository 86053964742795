import {TOKEN, USER} from "../helpers/constants/localStorageConstants";
import {MERCHANT_ROLE, ADMIN_ROLE} from "../helpers/constants/roles";

const login = async (email, password, role, rejectHandler) => {
	await fetch(`${window?.__CONFIG__?.REACT_APP_BASE_URL}${window?.__CONFIG__?.REACT_APP_API_NAMESPACE}/sign_in`, {
		method: "POST",
		credentials: "include",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			user: {
				email,
				role,
				password,
			}
		}),
	})
	.then((res) => {
		if (res.headers.get("authorization")) {
			localStorage.setItem(
				TOKEN,
				res.headers.get("authorization").replace("Bearer ", ""),
			);
		}
		return res.json();
	})
	.then((res) => {
		if (res.error) {
			rejectHandler(res.error);
			return;
		}
		localStorage.setItem(USER, JSON.stringify(res));

		if (res.role === ADMIN_ROLE) {
			window.location.pathname = `/${ADMIN_ROLE}`;
		}

		if (res.role === MERCHANT_ROLE) {
			window.location.pathname = `/${MERCHANT_ROLE}`;
		}
	})
	.catch((err) => {
		rejectHandler(err);
	});
}

export {
	login
}