import {useError} from "../../helpers/context/errorContext";
import {Alert} from 'react-bootstrap';
import './errorAlert.scss';


const ErrorAlert = () => {
	const { error } = useError();

	return error.isShow && (
		<Alert variant='danger' className="ErrorAlertRoot">
			<h4>Ошибка</h4>
			<p>{error.text}</p>
		</Alert>

	)
}

export default ErrorAlert;
