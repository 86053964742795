import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Spinner, Form, Badge, Modal} from "react-bootstrap";
import {getOrder} from "../../../api/orderAPI";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {
	OREDER_STATUSES,
	AWAITING_PROCCESING,
	PROCESSING,
	AWAITING_PICKUP,
	DELIVERED,
	PAYMENT_TYPES, IN_TRANSIT, DELIVERE_STATUSES, COURIER
} from "../../../helpers/constants/statuses";
import CourierModal from "./courierModal/courierModal";

const OrderPage = () => {
	const { orderId } = useParams();
	const navigate = useNavigate();
	const [order, setOrder] = useState([]);
	const [reason, setReason] = useState('');
	const [isOpenCancel, setOpenCancel] = useState(false);
	const [isOpenCourier, setOpenCourier] = useState(false);

	const getCurrentOrder = async () => {
		await getOrder(orderId)
		.then(res => {
			setOrder(res);
		})
	}

	useEffect(() => {
		getCurrentOrder();
	}, []);

	const closeHandler = () => {
		setOpenCancel(false);
		setReason('');
		getCurrentOrder();
	}

	const completeOrderHandler = async () => {
		order.completeDelivery = true;
		await order.save()
		.then(() => {
			getCurrentOrder()
		})
	}

	const cancelOrderHandler = async () => {
		order.cancelByAdmin = true;
		order.statusComment = reason;

		await order.save()
		.then(() => {
			getCurrentOrder();
			setOpenCancel(false);
		})
	}

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				{order
					? (
						<>
							<CourierModal
								order={order}
								isOpen={isOpenCourier}
								closeHandler={() => setOpenCourier(false)}
							/>
							<Modal show={isOpenCancel} onHide={() => closeHandler()}>
								<Modal.Header closeButton>
									<Modal.Title>Отмена заказа</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label>Причина отмены</Form.Label>
										<Form.Control as="textarea" value={reason} onChange={e => setReason(e.target.value)} rows={3} />
									</Form.Group>
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={() => closeHandler()}>
										Закрыть
									</Button>
									<Button variant="danger" disabled={!reason} onClick={() => cancelOrderHandler()}>
										Отменить заказ
									</Button>
								</Modal.Footer>
							</Modal>
							<Button
								variant="dark"
								onClick={() => {navigate(`/admin/orders`)}}
								className="marginBottom20"
							>Назад</Button>
							<div className="flex-space-between marginBottom20">
								<h1>Заказ: {order.publicUid}</h1>
								<div className="flex">
									{(order.status === IN_TRANSIT && order.deliveryType === DELIVERE_STATUSES[COURIER].origin) && (
										<Button
											variant="dark"
											className="marginLeft10"
											onClick={() => completeOrderHandler()}
										>Завершить заказ</Button>
									)}
									{(order.status === AWAITING_PROCCESING || order.status === PROCESSING || order.status === AWAITING_PICKUP) && (
										<Button
											variant="dark"
											className="marginLeft10"
											onClick={() => setOpenCancel(true)}
										>Отменить заказ</Button>
									)}
								</div>

							</div>

							<div className="flex-space-between marginBottom20">
								<div className="flex flex-column">
									<div className="marginBottom10">
										Текущий статус заказа:
										<Badge bg={(order.status === DELIVERED || order.status === IN_TRANSIT) ? "success" : "warning"}>
											{OREDER_STATUSES[order.status]?.translate || ''}
										</Badge>
									</div>
								</div>
							</div>
							{(order.pickupLocation) && (
								<div className="block marginBottom20">
									<h4>Заказ отдадут курьеру по адресу:</h4>
									<div className="marginBottom20">
										{order?.pickupLocation?.address}
									</div>
								</div>
							)}
							{(order.courier) && (
								<div className="block marginBottom20">
									<h4 className="marginBottom10">Курьер который будет доставлять заказ:</h4>
									<div>
										{`Имя: ${order?.courier?.surname || ''} ${order?.courier?.name || ''} ${order?.courier?.patronym || ''}`}
									</div>
									<div>
										{`Телефон: ${order?.courier?.phone || ''}`}
									</div>
								</div>
							)}
							<div className="grid2x block marginBottom20">
								<div>
									<h4>Информация о клиенте:</h4>
									<div className="marginBottom20">
										<div>Имя: {`${order?.user?.surname || ''} ${order?.user?.name || ''}`}</div>
										<div>Телефон: {`${order?.user?.phone || ''}`}</div>
									</div>
								</div>
								<div>
									{(order.orderItems && order.orderItems.length > 0) && (
										<>
											<h4>Состав заказа:</h4>
											<div className="marginBottom40">
												{order.orderItems.map((item, idx) => (
													<div>{`${idx + 1}) ${item.product.name || 'Имя не найдено'} - Количество ${item.quantity}шт. Цена - ${(item.price) / 100 } P`}</div>
												))}
											</div>
										</>
									)}
								</div>
							</div>
							<div className="grid2x block marginBottom20">
								<div>
									<h4 className="marginBottom20">Общая стоимость заказа: <b>{`${order.totalPrice / 100} P`}</b></h4>
									<h4 className="marginBottom20">Общая стоимость товаров в заказу: <b>{`${order.itemsPrice / 100} P`}</b></h4>
									<h4 className="marginBottom20">Сумма доставки: <b>{`${order.deliveryPrice / 100} P`}</b></h4>
									<h4 className="marginBottom20">Тип оплаты: <b>{`${PAYMENT_TYPES[order.paymentType]}`}</b></h4>
									<h4 className="marginBottom20">Тип доставки: <b>{`${order.deliveryType === 'courier' ? 'Курьером' : 'Самовывоз'}`}</b></h4>
								</div>
								<div>
									{order.comment && (
										<>
											<h3>Комментарий к заказу:</h3>
											<div className="marginBottom40">
												{order.comment}
											</div>
										</>
									)}
									{order.statusComment && (
										<>
											<h3>Комментарий к отмене заказа:</h3>
											<div className="marginBottom40">
												{order.statusComment}
											</div>
										</>
									)}
								</div>
							</div>
							<Button
								variant="dark"
								className="marginBottom20"
								style={{width: '100%'}}
								onClick={() => setOpenCourier(true)}
							>Назначить курьера</Button>
						</>
					)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default OrderPage