import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

const ErrorProvider = ({ children }) => {
	const [error, setError] = useState({
		isShow: false,
		text: ''
	});

	const setNewError = (e) => {
		setError(e);
		setTimeout(() => {setError({isShow: false, text: ''})}, 4000)
	};

	return (
		<ErrorContext.Provider value={{ error, setNewError }}>
			{children}
		</ErrorContext.Provider>
	);
};

const useError = () => {
	return useContext(ErrorContext);
};

export { ErrorProvider, useError };