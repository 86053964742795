import {Button, Card} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const MarketCard = ({market}) => {
	const navigate = useNavigate();
	return (
		<Card>
			<Card.Img variant="top" src={market.logo} />
			<Card.Body>
				<Card.Title><b>{market?.unverifiedChanges?.name || market?.name}</b></Card.Title>
				<Card.Text>{market?.unverifiedChanges?.description || market?.description}</Card.Text>
				<Button className="marginTop10" variant="dark" onClick={() => {navigate(`/merchant/${market.id}/marketplace`);}}>
					Перейти на страницу бизнеса
				</Button>
			</Card.Body>
		</Card>
	)
};

export default MarketCard;