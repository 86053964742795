import MarketplaceLocationsModel from "../models/marketplaceLocationsModel";

const getAllLocations = async () => {
	const res = await MarketplaceLocationsModel.all();
	return res.data;
};

const getLocationsForMarketplace = async (marketplaceId) => {
	const res = await MarketplaceLocationsModel.where({marketplaceId: marketplaceId}).all();
	return res.data;
};

export {
	getAllLocations,
	getLocationsForMarketplace,
}