import {useEffect, useState} from 'react';
import { format } from 'date-fns';
import {useNavigate, useParams} from "react-router-dom";
import {Table, Button, Spinner, Form, InputGroup, Tab, Tabs} from "react-bootstrap";
import {
	getAllOrdersByDeliveryType,
	searchAllOrdersByDeliveryType,
	searchAllOrdersByNumber
} from "../../../api/orderAPI";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {OREDER_STATUSES, DELIVERE_STATUSES} from "../../../helpers/constants/statuses";
import PaginationComponent from "../../../components/pagination/pagination";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";

const OrdersPage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [orders, setOrders] = useState([]);
	const [key, setKey] = useState('courier');
	const [total, setTotal] = useState(0);
	const [activePage, setActivePage] = useState(1);
	const [searchStr, setSearchStr] = useState('');

	const getOrders = async (pageNumber = null) => {
		await getAllOrdersByDeliveryType(pageNumber || activePage, 'courier', false)
		.then(res => {
			setOrders(res.data);
			setTotal(res.total);
		})
	};

	useEffect(() => {
		getOrders();
	}, []);

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		getOrders(pageNumber);
	};

	const searchHandler = async () => {
		// await searchAllOrdersByDeliveryType(
		// 	searchStr,
		// 	1,
		// 	key === 'courier_done' ? 'courier' : key,
		// 	key === 'courier_done' ? true : key === 'courier'? false : null,
		// )
		await searchAllOrdersByNumber(searchStr)
		.then(res => {
			setOrders(res);
		})
	};

	const resetHandler = () => {
		setSearchStr('');
		getOrders();
	};

	const setTabHandler = async (key) => {
		setKey(key);

		if (key === 'courier') {
			await getAllOrdersByDeliveryType(1, key, false)
			.then(res => {
				setOrders(res.data);
				setTotal(res.total);
			})
		}

		if (key === 'courier_done') {
			await getAllOrdersByDeliveryType(1, 'courier', true)
			.then(res => {
				setOrders(res.data);
				setTotal(res.total);
			})
		}

		if (key === 'self_pickup') {
			await getAllOrdersByDeliveryType(1, key, null)
			.then(res => {
				setOrders(res.data);
				setTotal(res.total);
			})
		}
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				{orders
					? orders.length > 0
						? (
							<>
								<InputGroup className="marginBottom40">
									<InputGroup.Text>Поиск</InputGroup.Text>
									<Form.Control
										aria-label="Поиск"
										placeholder="Введите номер заказа"
										onChange={e => setSearchStr(e.target.value)}
										value={searchStr}
									/>
									<Button
										variant="dark"
										onClick={() => searchHandler()}
									>Найти</Button>
								</InputGroup>

								<Tabs
									activeKey={key}
									onSelect={(k) => {
										if (key !== k) {
											setTabHandler(k)
										}
									}}
								>
									<Tab eventKey="courier" title="Заказы без курьера"/> {/* заказы без курьера - со статусами awaiting_processing,processing,awaiting_pickup */}
									<Tab eventKey="courier_done" title="Заказы с назначеным курьером"/> {/* заказы самовывоз у которых есть курьер */}
									<Tab eventKey="self_pickup" title="Самовывоз"/> {/* заказы самовывоз */}
								</Tabs>
								<Table>
									<thead>
									<tr>
										<th>Номер заказа</th>
										<th>Статус заказа</th>
										<th>Тип доставки</th>
										<th>Дата создания заказа</th>
										<th>Адрес выдачи</th>
									</tr>
									</thead>
									<tbody>
									{orders.map(order => (
										<tr key={order.id}>
											<td
												onClick={() => navigate(`/admin/orders/${order.id}`)}
												style={{textDecoration: 'underline', cursor: 'pointer'}}
											>
												{order.publicUid || 'Номер отсутствует'}
											</td>
											<td>{OREDER_STATUSES[order.status].translate}</td>
											<td>{DELIVERE_STATUSES[order.deliveryType].translate}</td>
											<td>{format(new Date(order.createdAt), 'dd.MM.yyyy - hh:mm')}</td>
											<td>{order?.pickupLocation?.address || 'Адрес выдачи не указан'}</td>
										</tr>
									))}
									</tbody>
								</Table>
								<PaginationComponent
									total={total}
									activePage={activePage}
									handlePageChange={(pn) => handlePageChange(pn)}
								/>
							</>
						)
						: (
							<div className="flex-column-center">
								<h1 style={{display: 'block'}}>Заказов пока нет</h1>
								<Button
									variant="dark"
									className="marginTop20"
									onClick={() => resetHandler()}
								>Отмена</Button>
							</div>
						)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default OrdersPage