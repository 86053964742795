import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, InputGroup, Alert} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/pageWrappers/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import ProductModel from "../../../models/productModel";
import {getAllCategoriesForMarketplace} from "../../../api/categoryAPI";

const CreateProductsPage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [photo, setPhoto] = useState('');
	const [categories, setCategories] = useState([]);
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		composition: '',
		price: '',
		discountPrice: '',
		categoryId: '',
	});
	const [errors, setErrors] = useState({
		name: null,
		description: null,
		category: null,
		price: null,
		composition: null
	});

	const getCategories = async () => {
		await getAllCategoriesForMarketplace(marketplaceId)
		.then(res => {
			setCategories(res);
		})
	};

	useEffect(() => {
		getCategories();
	}, []);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}));
	};

	const attachPhotoHandler = (file) => {
		attachHandler(file, (f, c) => setPhoto({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		if (!formData.name || !formData.price || !formData.description || !formData.categoryId || !formData.composition) {
			setErrors({
				name: !formData.name ? 'Необходимо заполнить название' : null,
				description: !formData.description ? 'Необходимо заполнить описание' : null,
				price: !formData.price ? 'Необходимо заполнить цену' : null,
				category: !formData.categoryId ? 'Необходимо выбрать категорию, если категории нет её нужно создать' : null,
				composition: !formData.composition ? 'Необходимо расписать состав' : null,
			})
		} else {
			const currentProduct = new ProductModel();

			currentProduct.unverifiedChanges = {
				name: formData.name,
				description: formData.description,
				composition: formData.composition,
			};
			currentProduct.price = formData.price * 100;
			currentProduct.discountPrice = formData.discountPrice === '' ? null : formData.discountPrice * 100;
			currentProduct.category = categories.find(category => category.id === formData.categoryId);

			if (photo?.file) {
				currentProduct.unverifiedImage = photo && (await uploadHandler(photo));
			}

			await currentProduct.save({ with: 'category.id'})
			.then(() => {
				navigate(`/merchant/${marketplaceId}/products`);
			})
		}
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				<Button
					variant="dark"
					onClick={() => {navigate(`/merchant/${marketplaceId}/products`)}}
					className="marginBottom20"
				>Назад</Button>
				<h1 className="marginBottom20">Создание товара</h1>
				<InputGroup className="mb-3">
					<InputGroup.Text id="basic-addon1">Название (обязательное поле)</InputGroup.Text>
					<Form.Control
						placeholder="Название"
						aria-label="Название"
						aria-describedby="basic-addon1"
						value={formData.name}
						isInvalid={errors.name}
						onChange={e => changeHandler('name', e.target.value)}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.name}
					</Form.Control.Feedback>
				</InputGroup>
				<InputGroup className="mb-3">
					<InputGroup.Text id="basic-addon2">Описание</InputGroup.Text>
					<Form.Control
						placeholder="Описание"
						aria-label="Описание"
						aria-describedby="basic-addon2"
						value={formData.description}
						isInvalid={errors.description}
						onChange={e => changeHandler('description', e.target.value)}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.description}
					</Form.Control.Feedback>
				</InputGroup>
				<InputGroup className="mb-3">
					<InputGroup.Text id="basic-addon2">Состав</InputGroup.Text>
					<Form.Control
						placeholder="Состав"
						aria-label="Состав"
						aria-describedby="basic-addon2"
						value={formData.composition}
						isInvalid={errors.composition}
						onChange={e => changeHandler('composition', e.target.value)}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.composition}
					</Form.Control.Feedback>
				</InputGroup>
				<div className="grid2x">
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Цена ₽ (Обязательное поле)</InputGroup.Text>
						<Form.Control
							placeholder="Цена"
							aria-label="Цена"
							aria-describedby="basic-addon2"
							value={formData.price}
							isInvalid={errors.price}
							onChange={e => changeHandler('price', e.target.value)}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.price}
						</Form.Control.Feedback>
					</InputGroup>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Цена со скидкой ₽</InputGroup.Text>
						<Form.Control
							placeholder="Цена со скидкой"
							aria-label="Цена со скидкой"
							aria-describedby="basic-addon2"
							value={formData.discountPrice}
							onChange={e => changeHandler('discountPrice', e.target.value)}
						/>
					</InputGroup>
				</div>
				{categories.length === 0 && (
					<Alert variant='warning' className="marginBottom20">
						У вас нет ни одной категории для товара. Создать товар без категории невозможно. Перейдите в раздел "Управление категориями" в меню слева и создайте категорию товара.
					</Alert>
				)}
				<InputGroup className="mb-3 marginBottom20">
					<InputGroup.Text id="basic-addon2">Категория</InputGroup.Text>
					<Form.Select
						value={formData.categoryId}
						isInvalid={errors.category}
						onChange={(c) => changeHandler('categoryId', c.target.value)}
					>
						<option>Выберите категорию</option>
						{categories.map(c => (
							<option key={c.name} value={c.id}>{c.name}</option>
						))}
					</Form.Select>
					<Form.Control.Feedback type="invalid">
						{errors.category}
					</Form.Control.Feedback>
				</InputGroup>
				<DropZoneUploader
					preview={photo}
					acceptPreviewHandler={(f) => {
						if (f) attachPhotoHandler(f[0]);
					}}
					className="marginBottom20"
					text="Прикрепите фото товара в формате jpeg или png"
				/>
				<Button
					variant="dark"
					style={{display: 'block', width: '100%'}}
					onClick={() => submitHandler()}
					className="marginBottom20"
				>Сохранить</Button>
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default CreateProductsPage