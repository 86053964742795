import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Button, ListGroup, Spinner, Alert, InputGroup, Form} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/pageWrappers/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import CategoryModel from "../../../models/categoryModel";
import {getAllCategoriesForMarketplace} from "../../../api/categoryAPI";
import {getMarketplace} from "../../../api/marketplaceAPI";

const CategoriesPage = () => {
	const { marketplaceId } = useParams();
	const [currentMarketplace, setCurrentMarketplace] = useState(null);
	const [categories, setCategories] = useState(null);
	const [isShowAlert, setShowAlert] = useState({
		status: false,
		text: ''
	});
	const [isShowCreate, setShowCreate] = useState(false);
	const [isShowUpdate, setShowUpdate] = useState({
		status: false,
		text: ''
	});
	const [name, setName] = useState('');
	const [updateName, setUpdateName] = useState('');

	const getCategories = async () => {
		await getAllCategoriesForMarketplace(marketplaceId)
		.then(res => {
			setCategories(res);
		})
	};

	useEffect(() => {
		getCategories();
		getMarketplace(marketplaceId)
		.then(res => setCurrentMarketplace(res));
	}, []);

	const deleteHandler = async (category) => {
		await category.destroy();
		await getCategories();
		setShowAlert({
			status: false,
			text: ''
		});
	}

	const createHandler = async () => {
		const currentCategory = new CategoryModel();
		currentCategory.name = name;
		currentCategory.marketplace = currentMarketplace;
		await currentCategory.save({ with: 'marketplace.id'});
		await getCategories();
		setShowCreate(false);
		setName('');
	}

	const updateHandler = async (category) => {
		category.name = updateName;
		await category.save();
		await getCategories();
		setUpdateName('');
	}

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{!isShowCreate && (
					<Button variant="dark" onClick={() => setShowCreate(true)} className="marginBottom20">
						Добавить категорию
					</Button>
				)}
				{isShowCreate && (
					<div className="marginBottom20 flex-space-between">
						<InputGroup>
							<InputGroup.Text id="inputGroup-sizing-sm">Название категории</InputGroup.Text>
							<Form.Control
								placeholder="Название категории"
								aria-label="Small"
								aria-describedby="inputGroup-sizing-sm"
								value={name}
								onChange={e => setName(e.target.value)}
							/>
							<div>
								<Button variant="success" onClick={() => createHandler()}>
									Сохранить
								</Button>
								<Button variant="danger" onClick={() => {
									setName('');
									setShowCreate(false);
								}}>
									Отмена
								</Button>
							</div>
						</InputGroup>
					</div>
				)}
				{categories
					? (
						<div>
							{categories.length > 0
								? (<ListGroup>
									{categories.map(category => (
										<ListGroup.Item key={category.name} className="d-flex justify-content-between align-items-start">
											{(isShowAlert.status && isShowAlert.text === `${category.name}_${category.id}`) ? (
												<Alert
													key={category.name}
													variant="warning"
													style={{ width: '100%', margin: '0', display: 'flex', justifyContent: 'space-between'}}
												>
													{`Вы действительно хотите удалить категорию "${category.name}"?`}
													<div style={{ display: 'flex', columnGap: '12px'}}>
														<Button
															variant="success"
															onClick={() => deleteHandler(category)}
														>Удалить</Button>
														<Button
															variant="danger"
															onClick={() => setShowAlert({status: false, text: ''})}
														>Отмена</Button>
													</div>
												</Alert>
											) : (
												<>
													{(isShowUpdate.status && isShowUpdate.text === category.name) ? (
														<div className="flex-space-between">
															<InputGroup>
																<InputGroup.Text id="inputGroup-sizing-sm">Название категории</InputGroup.Text>
																<Form.Control
																	placeholder="Название категории"
																	aria-label="Small"
																	aria-describedby="inputGroup-sizing-sm"
																	value={updateName}
																	onChange={e => setUpdateName(e.target.value)}
																/>
																<div>
																	<Button variant="success" onClick={() => updateHandler(category)}>
																		Сохранить
																	</Button>
																	<Button variant="danger" onClick={() => {
																		setUpdateName('');
																		setShowUpdate(true);
																	}}>
																		Отмена
																	</Button>
																</div>
															</InputGroup>
														</div>
													) : (
														<>
															<b>{category.name}</b>
															<div>
																<Button
																	variant="dark"
																	className="marginRight20"
																	onClick={() => {
																		setShowUpdate({status: true, text: category.name});
																		setUpdateName(category.name)
																	}}
																>
																	Редактировать категорию
																</Button>
																<Button
																	variant="dark"
																	onClick={() => setShowAlert({status: true, text: `${category.name}_${category.id}`})}
																>
																	Удалить категорию
																</Button>
															</div>
														</>
													)}
												</>
											)}
										</ListGroup.Item>
									))}
								</ListGroup>)
								: (
									<div className="flex-center">
										<h1>Категории не найдены</h1>
									</div>
								)
							}
						</div>
					)
					: (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default CategoriesPage