import ProductModel from "../models/productModel";

const getAllProducts = async () => {
	const res = await ProductModel.all();
	return res.data;
};

const getProductsForMarketplace = async (marketplaceId) => {
	const res = await ProductModel.where({marketplaceId: marketplaceId})
	.all();
	return res.data;
};

const getProduct = async (productId) => {
	const res = await ProductModel.includes(['category']).find(productId);
	return res.data;
};

export {
	getAllProducts,
	getProductsForMarketplace,
	getProduct
}