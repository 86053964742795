import {attr, hasMany} from "spraypaint";
import ApplicationRecord from "./baseModel";

const TagModel = ApplicationRecord.extend({
	static: { jsonapiType: "tags" },
	attrs: {
		id: attr(),
		name: attr(),
		image: attr(),
		imageUrl: attr(),
		rootTag: attr(),
		marketplaceTags: hasMany(),
	}
})

export default TagModel