import CategoryModel from "../models/categoryModel";

const getAllCategories = async () => {
	const res = await CategoryModel.all();
	return res.data;
};

const getAllCategoriesForMarketplace = async (marketplaceId) => {
	const res = await CategoryModel.where({marketplaceId: marketplaceId}).all();
	return res.data;
};

const getCategory = async (categoryId) => {
	const res = await CategoryModel.where({id: categoryId}).all();
	return res.data;
};

export {
	getAllCategories,
	getCategory,
	getAllCategoriesForMarketplace
}