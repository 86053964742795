import NotificationModel from "../models/notificationModel";

const getAllNotifications = async ({ page }) => {
	const res = await NotificationModel
	.page(page)
	.per(10)
	.includes(['recipient'])
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getAllNotificationsForUser = async ({recipientId, page, type}) => {
	const res = await NotificationModel
	.where({recipientId: recipientId, type: type})
	.page(page)
	.per(10)
	.includes(['recipient', 'event'])
	.stats({ total: 'count' })
	.order({ isRead: 'desc', createdAt: 'desc' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};


export {
	getAllNotifications,
	getAllNotificationsForUser,
}