import {Button, Form, InputGroup, Modal, Table} from "react-bootstrap";
import {getUsers, searchUsers} from "../../../../api/userAPI";
import {useEffect, useState} from "react";
import PaginationComponent from "../../../../components/pagination/pagination";

const CourierModal = ({isOpen, order, closeHandler}) => {
	const [couriers, setCouriers] = useState([]);
	const [searchStr, setSearchStr] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [total, setTotal] = useState(0);

	const getCouriers = async (pageNumber = null) => {
		await getUsers('courier', pageNumber || activePage, 8)
		.then(res => {
			setCouriers(res.data);
			setTotal(res.total);
		})
	};

	useEffect(() => {
		getCouriers();
	}, []);

	const searchHandler = async (pageNumber = null) => {
		await searchUsers('courier', pageNumber || activePage, searchStr, 8)
		.then(res => {
			setCouriers(res.data);
			setTotal(res.total);
		})
	};

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		setCouriers([]);
		getCouriers(pageNumber);
	};

	const resetHandler = () => {
		setActivePage(1);
		setSearchStr('');
		getCouriers();
	};

	const pickCourier = async (courier) => {
		order.courier = courier;
		await order.save({ with: 'courier.id'});
		closeHandler();
	};

	return (
		<Modal
			show={isOpen}
			onHide={() => closeHandler()}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton >
				<Modal.Title>Назначение курьера</Modal.Title>
			</Modal.Header>
			<Modal.Body >
				<InputGroup className="marginBottom20">
					<InputGroup.Text>Поиск</InputGroup.Text>
					<Form.Control
						aria-label="Поиск"
						placeholder="Введите имя или номер телефона"
						onChange={e => setSearchStr(e.target.value)}
						value={searchStr}
					/>
					<Button
						variant="dark"
						onClick={() => searchHandler(1)}
					>Найти</Button>
					{searchStr && (
						<Button
							variant="dark"
							onClick={() => resetHandler()}
						>Сбросить</Button>
					)}
				</InputGroup>

				<Table>
					<thead>
					<tr>
						<th>ФИО курьера</th>
						<th>Номер телеофна курьера</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{couriers.map(cr => (
						<tr key={cr.id}>
							<td>{`${cr.surname || ''} ${cr.name || ''} ${cr.patronym || ''}`}</td>
							<td>{`+ ${cr.phone || ''}`}</td>
							<td style={{textAlign: 'right'}}>
								<Button variant="success" onClick={() => pickCourier(cr)}>
									Назначить
								</Button>
							</td>
						</tr>
					))}
					</tbody>
				</Table>
				<PaginationComponent
					total={total}
					activePage={activePage}
					handlePageChange={(pn) => handlePageChange(pn)}
				/>
			</Modal.Body>
			<Modal.Footer >
				<Button variant="secondary" onClick={() => closeHandler()}>
					Закрыть
				</Button>
			</Modal.Footer>
		</Modal>
	)
};

export default CourierModal;