import UserModel from "../models/userModel";

const getCurrentUser = async (id) => {
	const res = await UserModel.find(id);
	return res.data;
};

const getUsers = async (role, page, per = 15) => {
	const res = await UserModel
	.where({role: role})
	.page(page)
	.per(per)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const searchUsers = async (role, page, searchStr, per = 15) => {
	const res = await UserModel
	.where({
		role: role,
		search: searchStr,
	})
	.page(page)
	.per(per)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getUser = async (userId) => {
	const res = await UserModel.find(userId);
	return res.data;
};

export {
	getUsers,
	getCurrentUser,
	searchUsers,
	getUser
}