import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ADMIN_ROLE, MERCHANT_ROLE, COURIER_ROLE, USER_ROLE, AllRolesArray} from "../../../helpers/constants/roles";
import {Button, Form, InputGroup, ButtonGroup} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { ru } from 'date-fns/locale/ru'
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import "react-datepicker/dist/react-datepicker.css";
import './createUserPage.scss';
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import UserModel from "../../../models/userModel";


const CreateUserPage = () => {
	registerLocale('ru', ru);
	const navigate = useNavigate();
	const [avatar, setAvatar] = useState('');
	const [formData, setFormData] = useState({
		name: '',
		surname: '',
		patronym: '',
		email: '',
		phone: '',
		role: ADMIN_ROLE,
		password: '',
		gender: 'male',
		birthday: new Date()
	});
	const [errors, setErrors] = useState({
		email: null,
		phone: null,
		password: null,
	})

	const changeHandler = (field, value) => {
		setFormData(p => ({...p, [field]: value}));
	};

	const attachAvatarHandler = (file) => {
		attachHandler(file, (f, c) => setAvatar({ file: f, checkSum: c }));
	};

	const saveUser = async () => {
		const currentUser = new UserModel();
		currentUser.name = formData.name;
		currentUser.surname = formData.surname;
		currentUser.patronym = formData.patronym;
		if ((formData.role === COURIER_ROLE) || (formData.role === USER_ROLE)) {
			currentUser.phone = formData.phone;
		}
		if ((formData.role === ADMIN_ROLE) || (formData.role === MERCHANT_ROLE)) {
			currentUser.email = formData.email;
			currentUser.password = formData.password;
		}
		currentUser.role = formData.role;
		currentUser.gender = formData.gender;
		currentUser.birthday = formData.birthday;

		if (avatar?.file) {
			currentUser.avatar = avatar && (await uploadHandler(avatar));
		}

		await currentUser.save()
		.then(() => {
			if (Object.keys(currentUser.errors).length > 0) {
				setErrors(currentUser.errors);
			} else {
				navigate('/admin/users')
			}
		})
	};

	const submitHandler = async () => {
		if (formData.role === ADMIN_ROLE || formData.role === MERCHANT_ROLE) {
			if (!formData.email || !formData.password || formData.password.length < 6) {
				setErrors({
					phone: null,
					email: !formData.email ? {fullMessage: 'Поле обязательное для заполнения'} : null,
					password: !formData.password
						? 'Поле обязательное для заполнения'
						: formData.password.length < 6 ? 'Длинна пароля минимум 6 символов' : null
				})
			} else {
				saveUser();
			}
		}

		if (formData.role === COURIER_ROLE || formData.role === USER_ROLE) {
			if (!formData.phone) {
				setErrors({
					phone: !formData.phone ? {fullMessage: 'Поле обязательное для заполнения'} : null,
					password: null,
					email: null
				})
			} else {
				saveUser();
			}
		}
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div>
					<Button
						variant="dark"
						onClick={() => navigate('/admin/users')}
						className="marginBottom20"
					>Назад</Button>
					<h1 className="marginBottom40">Создание пользователя</h1>
					<div className="marginBottom20">
						<DropZoneUploader
							preview={avatar}
							acceptPreviewHandler={(f) => {
								if (f) attachAvatarHandler(f[0]);
							}}
							className="marginBottom20"
							text="Прикрепите аватар пользователя в формате jpeg или png"
						/>
					</div>
					<div className="grid3x marginBottom20">
						<InputGroup>
							<InputGroup.Text>Фамилия</InputGroup.Text>
							<Form.Control
								placeholder="Введите фамилию"
								onChange={e => changeHandler('surname', e.target.value)}
								value={formData.surname}
							/>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Имя</InputGroup.Text>
							<Form.Control
								placeholder="Введите имя"
								onChange={e => changeHandler('name', e.target.value)}
								value={formData.name}
							/>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Отчество</InputGroup.Text>
							<Form.Control
								placeholder="Введите отчество"
								onChange={e => changeHandler('patronym', e.target.value)}
								value={formData.patronym}
							/>
						</InputGroup>
					</div>
					<div className="grid3x marginBottom20">
						<InputGroup style={{ flexWrap: 'nowrap'}}>
							<InputGroup.Text>Дата рождения</InputGroup.Text>
							<DatePicker
								locale="ru"
								selected={formData.birthday}
								onChange={(date) => changeHandler('birthday', date)}
								wrapperClassName="DatePikerWrapper"
								className="DatePikerInput"
								dateFormat="dd.MM.yyyy"
							/>
						</InputGroup>
						<InputGroup style={{ flexWrap: 'nowrap'}}>
							<InputGroup.Text>Пол</InputGroup.Text>
							<ButtonGroup style={{width: '100%'}}>
								<Button
									onClick={() => changeHandler('gender', 'male')}
									variant={formData.gender === 'male' ? "dark" : "secondary"}>Мужчина</Button>
								<Button
									onClick={() => changeHandler('gender', 'female')}
									variant={formData.gender === 'female' ? "dark" : "secondary"}>Женщина</Button>
							</ButtonGroup>
						</InputGroup>
						<InputGroup style={{width: '100%'}}>
							<InputGroup.Text>Роль</InputGroup.Text>
							<Form.Select
								aria-label="Выберите роль"
								aria-placeholder="Выберите роль"
								onChange={e => {
									changeHandler('role', e.target.value);
								}}
								value={formData.role}
							>
								{AllRolesArray.map(role => (
									<option value={role.value} key={role.value}>{role.label}</option>
								))}
							</Form.Select>
						</InputGroup>
					</div>
					<div className="grid3x marginBottom20">
						{((formData.role === ADMIN_ROLE) || (formData.role === MERCHANT_ROLE)) && (
							<InputGroup>
								<InputGroup.Text>Email</InputGroup.Text>
								<Form.Control
									placeholder="Введите email"
									onChange={e => changeHandler('email', e.target.value)}
									value={formData.email}
									isInvalid={!!errors.email}
								/>
								<Form.Control.Feedback type="invalid">
									{errors?.email?.fullMessage}
								</Form.Control.Feedback>
							</InputGroup>
						)}
						{((formData.role === COURIER_ROLE) || (formData.role === USER_ROLE)) && (
							<InputGroup>
								<InputGroup.Text>Телефон</InputGroup.Text>
								<Form.Control
									placeholder="Введите телефон"
									onChange={e => changeHandler('phone', e.target.value)}
									value={formData.phone}
									isInvalid={!!errors.phone}
								/>
								<Form.Control.Feedback type="invalid">
									{errors?.phone?.fullMessage}
								</Form.Control.Feedback>
							</InputGroup>
						)}

						{((formData.role === ADMIN_ROLE) || (formData.role === MERCHANT_ROLE)) && (
							<InputGroup>
								<InputGroup.Text>Пароль</InputGroup.Text>
								<Form.Control
									placeholder="Введите пароль"
									onChange={e => changeHandler('password', e.target.value)}
									value={formData.password}
									isInvalid={!!errors.password}
								/>
								<Form.Control.Feedback type="invalid">
									{errors?.password}
								</Form.Control.Feedback>
							</InputGroup>
						)}
					</div>
					<div className="flex-center">
						<Button
							onClick={() => submitHandler()}
							variant="dark"
						>Сохранить</Button>
					</div>
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default CreateUserPage;