import {attr, hasMany} from "spraypaint";
import ApplicationRecord from "./baseModel";

const CityModel = ApplicationRecord.extend({
	static: { jsonapiType: "cities" },
	attrs: {
		name: attr(),
		marketplaces: hasMany(),
	}
})

export default CityModel