import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {TOKEN, USER} from "./helpers/constants/localStorageConstants";
import ROUTES from "./helpers/constants/routes";
import {ErrorProvider} from "./helpers/context/errorContext";

import Notification from './components/notification/notification';

import LoginPage from "./pages/loginPage/loginPage";

// merchant pages
import MerchantMainPage from "./pages/merchant/mainPage/mainPage";
import MerchantMarketplacePage from "./pages/merchant/marketplacePage/marketplacePage";
import MerchantPromotionalPage from "./pages/merchant/promotionalPage/promotionalPage";
import MerchantNotificationPage from "./pages/merchant/notificationPage/notificationPage";
import MerchantStatisticsPage from "./pages/merchant/statisticsPage/statisticsPage";
import MerchantCreateMarketplacePage from "./pages/merchant/createMarketplacePage/createMarketplacePage";
import MerchantEditMarketplacePage from "./pages/merchant/editMarketplacePage/editMarketplacePage";
import MerchantProductsPage from "./pages/merchant/productsPage/productsPage";
import MerchantCategoriesPage from "./pages/merchant/categoriesPage/categoriesPage";
import MerchantCreateProductsPage from "./pages/merchant/createProductPage/createProductsPage";
import MerchantEditProductsPage from "./pages/merchant/editProductPage/editProductsPage";
import MerchantOrdersPage from "./pages/merchant/ordersPage/ordersPage";
import MerchantOrderPage from "./pages/merchant/orderPage/orderPage";

// admin pages
import AdminMainPage from "./pages/administrator/mainPage/mainPage";
import AdminUsersPage from "./pages/administrator/usersPage/usersPage";
import AdminCreateUserPage from "./pages/administrator/createUserPage/createUserPage";
import AdminEditUserPage from "./pages/administrator/editUserPage/editUserPage";
import AdminMarketplacesPage from "./pages/administrator/marketplacesPage/marketplacesPage";
import AdminMarketplacePage from "./pages/administrator/marketplacePage/marketplacePage";
import AdminTagsPage from "./pages/administrator/tagsPage/tagsPage";
import AdminOrdersPage from "./pages/administrator/ordersPage/ordersPage";
import AdminOrderPage from "./pages/administrator/orderPage/orderPage";
import AdminNotificationPage from "./pages/administrator/notificationPage/notificationPage";
import AdminSharesPage from "./pages/administrator/sharesPage/sharesPage";

const router = createBrowserRouter([
  { path: ROUTES.auth, element: <LoginPage/> },

  // merchant routes
  { path: ROUTES.merchant.main, element: <MerchantMainPage/> },
  { path: ROUTES.merchant.marketplace, element: <MerchantMarketplacePage/> },
  { path: ROUTES.merchant.promotional, element: <MerchantPromotionalPage/> },
  { path: ROUTES.merchant.notifications, element: <MerchantNotificationPage/> },
  { path: ROUTES.merchant.statistics, element: <MerchantStatisticsPage/> },
  { path: ROUTES.merchant.marketplace_create, element: <MerchantCreateMarketplacePage/> },
  { path: ROUTES.merchant.marketplace_edit, element: <MerchantEditMarketplacePage/> },
  { path: ROUTES.merchant.products, element: <MerchantProductsPage/> },
  { path: ROUTES.merchant.productsCreate, element: <MerchantCreateProductsPage/> },
  { path: ROUTES.merchant.productsEdit, element: <MerchantEditProductsPage/> },
  { path: ROUTES.merchant.categories, element: <MerchantCategoriesPage/> },
  { path: ROUTES.merchant.orders, element: <MerchantOrdersPage/> },
  { path: ROUTES.merchant.order, element: <MerchantOrderPage/> },

  // admin routes
  { path: ROUTES.admin.main, element: <AdminMainPage/> },
  { path: ROUTES.admin.users, element: <AdminUsersPage/> },
  { path: ROUTES.admin.users_create, element: <AdminCreateUserPage/> },
  { path: ROUTES.admin.users_edit, element: <AdminEditUserPage/> },
  { path: ROUTES.admin.marketplaces, element: <AdminMarketplacesPage/> },
  { path: ROUTES.admin.marketplace, element: <AdminMarketplacePage/> },
  { path: ROUTES.admin.tags, element: <AdminTagsPage/> },
  { path: ROUTES.admin.orders, element: <AdminOrdersPage/> },
  { path: ROUTES.admin.order, element: <AdminOrderPage/> },
  { path: ROUTES.admin.notifications, element: <AdminNotificationPage/> },
  { path: ROUTES.admin.shares, element: <AdminSharesPage/> },
]);

const App = ({ cable }) => {
  if (window.location.pathname === '/' && localStorage.getItem(TOKEN)) {
    const currentUser = JSON.parse(localStorage.getItem(USER));
    window.location.pathname = `/${currentUser?.role}`
    return
  }

  if (window.location.pathname === '/' && !localStorage.getItem(TOKEN)) {
    window.location.pathname = `/auth`
    return
  }

  return (
    <div>
      <Notification cable={cable}/>
      <ErrorProvider>
        <RouterProvider router={router} />
      </ErrorProvider>
    </div>
  )
}

export default App;
