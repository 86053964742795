import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";
import OrderModel from "./orderModel";
import ProductModel from "./productModel";

const OrderItemModel = ApplicationRecord.extend({
	static: { jsonapiType: "order_items" },
	attrs: {
		id: attr(),
		quantity: attr(),
		price: attr(),
		totalPrice: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),

		order: belongsTo(),
		product: belongsTo(ProductModel),
	}
})

export default OrderItemModel