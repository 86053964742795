import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Container} from 'react-bootstrap'
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {TOKEN, USER} from "../../../helpers/constants/localStorageConstants";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {getUserMarketplaces} from "../../../api/marketplaceAPI";
import './mainPage.scss';
import MarketCard from "../../../components/cards/marketCard/marketCard";

const MainPage = () => {
	const user = JSON.parse(localStorage.getItem(USER));
	const navigate = useNavigate();
	const [ marketplaces, setMarketplaces ]= useState([]);

	useEffect(() => {
		getUserMarketplaces(user.id)
		.then(res => {
			setMarketplaces(res)
		})
	},[]);

	const logoutHandler = () => {
		localStorage.removeItem(TOKEN);
		localStorage.removeItem(USER);
		navigate('/auth');
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<Container className="marginTop40 marginBottom40">
				<Button className='PageWrapperLogout' variant="dark" onClick={() => logoutHandler()}>Выйти</Button>
				<h1 className="marginBottom60">
					 Выберите бизнес и перейдите на его страницу
				</h1>

				{marketplaces.length > 0 ? (
					<>
						<div className="block marginBottom20">
							<h1 className="marginBottom20">Черновики бизнесов</h1>
							<div className="grid3x">
								<Button variant="dark" onClick={() => {navigate("/merchant/marketplace/create");}}>
									<h5>Добавить новый бизнес</h5>
								</Button>
								{marketplaces.filter(i => i.status === 'draft').map(market => (
									<MarketCard market={market} key={market.id}/>
								))}
							</div>
						</div>
						<div className="block marginBottom20">
							<h1 className="marginBottom20">Ожидающие верификации</h1>
							<div className="grid3x">
								{marketplaces.filter(i => i.status === 'verification').map(market => (
									<MarketCard market={market} key={market.id}/>
								))}
							</div>
						</div>
						<div className="block marginBottom20">
							<h1 className="marginBottom20">Опубликованные бизнесы</h1>
							<div className="grid3x">
								{marketplaces.filter(i => i.attributes.status === 'published').map(market => (
									<MarketCard market={market} key={market.id}/>
								))}
							</div>
						</div>
					</>
				) : (
					<Button variant="dark" onClick={() => {navigate("/merchant/marketplace/create");}}>
						Добавить новый бизнес
					</Button>
				)}
			</Container>
		</ProtectedWrapper>
	)
}

export default MainPage;