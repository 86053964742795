import {useState} from "react";
import {InputGroup, Form, Button, Col, Row, Container} from 'react-bootstrap'
import {AdminPanelRolesArray, MERCHANT_ROLE} from "../../helpers/constants/roles";
import {login} from '../../api/authAPI';
import './loginPage.scss';

const LoginPage = () => {
	const [error, setError] = useState(null);
	const [formData, setFormData] = useState({
		login: '',
		password: '',
		role: MERCHANT_ROLE,
	});

	const submitHandler = () => {
		login(
			formData.login,
			formData.password,
			formData.role,
			(err) => setError(err)
		)
	};

	return (
		<div className="Root">
			<Container>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Text>Почта</InputGroup.Text>
							<Form.Control
								aria-label="Логин"
								onChange={e => {
									setFormData(p => ({login: e.target.value, role: p.role, password: p.password}))
								}}
								value={formData.login}
								isInvalid={!!error}
							/>
							<Form.Control.Feedback type="invalid">
								Неверная почта или пароль
							</Form.Control.Feedback>
						</InputGroup>
						<InputGroup className="marginTop20 marginBottom20">
							<InputGroup.Text>Пароль</InputGroup.Text>
							<Form.Control
								aria-label="Пароль"
								onChange={e => {
									setFormData(p => ({login: p.login, role: p.role, password: e.target.value}))
								}}
								value={formData.password}
								type="password"
								isInvalid={!!error}
							/>
							<Form.Control.Feedback type="invalid">
								Неверная почта или пароль
							</Form.Control.Feedback>
						</InputGroup>
						<InputGroup style={{width: '100%'}}>
							<InputGroup.Text>Роль</InputGroup.Text>
							<Form.Select
								aria-label="Выберите роль"
								aria-placeholder="Выберите роль"
								onChange={e => {
									setFormData(p => ({login: p.login, role: e.target.value, password: p.password }));
								}}
								value={formData.role}
								isInvalid={!!error}
							>
								{AdminPanelRolesArray.map(role => (
									<option value={role.value} key={role.value}>{role.label}</option>
								))}
							</Form.Select>
							<Form.Control.Feedback type="invalid">
								Убедитесь, что выбрали корректную роль
							</Form.Control.Feedback>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col className="flex-center">
						<Button
							variant="primary"
							className="marginTop20"
							disabled={!formData.login || !formData.password}
							onClick={() => submitHandler()}
						>Войти</Button>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default LoginPage;