import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import './mainPage.scss';

const MainPage = () => {
	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<h3>Панель администратора</h3>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default MainPage;