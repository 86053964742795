import {useEffect, useState} from 'react';
import {getCurrentSCModel} from '../../../api/serviceConfigAPI';
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Button, Form, InputGroup} from "react-bootstrap";

const SharesPage = () => {
	const [serviceConfig, setServiceConfig] = useState(null);
	const [isEnable, setIsEnable] = useState(null);
	const [amount, setAmount] = useState('');

	const getCurrentConfig = async () => {
		await getCurrentSCModel()
		.then(res => {
			setServiceConfig(res)
			setAmount(res?.freeDeliveryPromo.from_order_amount / 100);
			setIsEnable(res?.freeDeliveryPromo.enabled);
		});
	};

	useEffect(() => {
		getCurrentConfig()
	}, []);

	const updateConfig = async () => {
	  serviceConfig.freeDeliveryPromo = {
			enabled: isEnable,
			from_order_amount: amount * 100
		}
		await serviceConfig.save()
		.then(() => {
			getCurrentConfig();
		})
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div className="block">
					<h3 className="marginBottom20">Бесплатная доставка</h3>
					<Form.Check
						type="switch"
						id="custom-switch"
						label="Включить бесплатную доставку на платформе"
						checked={isEnable}
						onChange={() => setIsEnable(p => !p)}
					/>

					<div className="flex marginTop20">
						<InputGroup>
							<InputGroup.Text id="basic-addon2">Сумма с которой доставка будет бесплатной (в рублях)</InputGroup.Text>
							<Form.Control
								placeholder="Введите сумму"
								aria-label="Сумма с которой доставка будет бесплатной"
								aria-describedby="basic-addon2"
								value={amount}
								disabled={!isEnable}
								onChange={e => setAmount(e.target.value)}
							/>
						</InputGroup>
						{(
							serviceConfig?.freeDeliveryPromo?.enabled !== isEnable ||
							(Number(serviceConfig?.freeDeliveryPromo?.from_order_amount / 100)) !== (Number(amount))
						) && (
							<Button
								style={{width: '300px'}}
								variant="dark"
								onClick={() => updateConfig()}
							>
								Сохранить
							</Button>
						)}
					</div>

				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default SharesPage