import {attr, belongsTo, hasMany} from "spraypaint";
import ApplicationRecord from "./baseModel";
import MarketplaceModel from './marketplaceModel';
import ProductModel from './productModel';

const CategoryModel = ApplicationRecord.extend({
	static: { jsonapiType: "categories" },
	attrs: {
		id: attr(),
		name: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		marketplace: belongsTo(MarketplaceModel),
		products: hasMany(ProductModel)
	}
})

export default CategoryModel