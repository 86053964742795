import {Button} from "react-bootstrap";

const Tags = ({items = [1,2,3,4], activeItem, onClickHandler}) => {
	return (
		<div className="flex flex-wrap">
			{items.map(tag => (
				<Button
					key={tag.name}
					variant={activeItem?.id === tag?.id ? 'dark' : 'outline-secondary'}
					className="marginRight10 marginBottom10"
					onClick={() => onClickHandler(tag)}
				>
					{tag.name}
				</Button>
			))}
		</div>
	)
};

export default Tags;