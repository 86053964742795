import React from 'react';
import ReactDOM from 'react-dom/client';
import { createConsumer, INTERNAL } from '@rails/actioncable';
import reportWebVitals from './helpers/reportWebVitals';
import {TOKEN} from "./helpers/constants/localStorageConstants";
import App from './App';

import './styles/reset.scss';
import './styles/csshelper.scss';
import 'bootstrap/dist/css/bootstrap.css';

const AUTH_TOKEN = localStorage.getItem(TOKEN) || '';
let cable;

if (AUTH_TOKEN && !INTERNAL.protocols.hasOwnProperty(`jwt_${AUTH_TOKEN}`)) {
  const URL = `${window.__CONFIG__.REACT_APP_WS_URL}/websocket`;
  INTERNAL.protocols.push(`jwt_${AUTH_TOKEN}`);
  cable = createConsumer(URL);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App cable={cable}/>
  </React.StrictMode>
);

reportWebVitals();
