import TagModel from "../models/tagModel";

const getAllTags = async () => {
	const res = await TagModel.all();
	return res.data;
};

const getTag = async (tagId) => {
	const res = await TagModel.find(tagId);
	return res.data;
};

export {
	getAllTags,
	getTag,
}