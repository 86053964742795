import {TOKEN, USER} from "../../../helpers/constants/localStorageConstants";
import ErrorAlert from "../../errorAlert/errorAlert";

const ProtectedWrapper = ({
	children,
	className,
	role
}) => {
	const currentUser = JSON.parse(localStorage.getItem(USER));

	if (!localStorage.getItem(TOKEN)) {
		window.location.pathname = '/auth'
		return
	}

	if (currentUser?.role !== role) {
		window.location.pathname = `/${currentUser?.role}`
		return
	}

	return (
		<div className={className}>
			<ErrorAlert/>
			{children}
		</div>
	)
};

export default ProtectedWrapper;