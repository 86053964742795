import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";
import EventModel from "./eventModel";

const NotificationModel = ApplicationRecord.extend({
	static: { jsonapiType: "notifications" },
	attrs: {
		id: attr(),
		type: attr(),
		isRead: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		recipient: belongsTo(),
		event: belongsTo(EventModel),
	}
})

export default NotificationModel